import React from "react";
import magazine1 from "../assets/img/bottom-Image.png";
import magazine2 from "../assets/img/bottom-image2.png";
import GreenTick from "../assets/img/g52-9-5.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, Row } from "reactstrap";
let data = [
    "Actuele artikelen over gezondheid, financiën en levensvragen",
    "Aangrijpende en waargebeurde verhalen",
    "Tips voor dagjes uit, wonen en het huishouden",
    "Leuke puzzels en winacties",
];
const BottomSection = () => {
    const history = useHistory();
    return (
        <div>
            <Card className="bottomSection">
                <Row className="row justify-content-center">
                    <div className="bottomLeftCol  col-md-6 img-fluid order-md-1 order-2">
                        <img
                            src={magazine1}
                            alt="img"
                            className="img-fluid magazine1 d-md-block d-none"
                        />
                        <img
                            src={magazine2}
                            alt="img"
                            className="img-fluid magazine2 d-md-none d-block"
                        />
                    </div>
                    <div className="BottomRightCol col-md-6 order-md-2 order-1">
                        <p className="my-4 mx-4">
                            Dit onderzoek is op verzoek van{" "}
                            <b>
                                MAX Magazine, het grootste magazine van
                                Nederland{" "}
                            </b>
                            met de meeste pagina’s voor de laagste prijs.
                        </p>
                        <h6 className="mx-4">Boordevol:</h6>
                        {data.map((e) => {
                            return (
                                <Card className="py-4 px-3 my-3 mx-4 cards  ">
                                    <h5 className="d-flex mb-0">
                                        <img
                                            src={GreenTick}
                                            className="mr-4 GreenTick"
                                        />
                                        <b>{e}</b>
                                    </h5>
                                </Card>
                            );
                        })}

                        <div className="text-center my-5  ml-4 d-md-none d-flex mr-auto">
                            <Button
                                className="py-3 px-4"
                                onClick={() => history.push("/questionPage")}
                                color="warning"
                            >
                                <span className="large-screen-text">
                                    Start deelname aan het onderzoek
                                </span>
                                <span className="small-screen-text">
                                    Start deelname
                                </span>{" "}
                                <i className="fa fa-solid fa-chevron-right mx-3"></i>{" "}
                            </Button>
                        </div>
                    </div>
                </Row>
                <div className="text-center my-3 d-md-block d-none">
                    <Button
                        className=""
                        onClick={() => history.push("/questionPage")}
                        color="warning"
                    >
                        <span className="large-screen-text">
                            Start deelname aan het onderzoek
                        </span>
                        <span className="small-screen-text">
                            Start deelname
                        </span>{" "}
                        <i className="fa fa-solid fa-chevron-right mx-3"></i>{" "}
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default BottomSection;
