// postLead

import { Toast } from "reactstrap";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var leads = RepositoryFactory.get("repo");

export const createLoader = (val) => async (dispatch) => {
    dispatch({
        type: "CREATE_LOADER",
        payload: val,
    });
};

export const postLead =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        console.log("data", data);
        let { bootCheck, ...payload } = data;
        console.log(payload);
        await dispatch(createLoader(true));
        if (bootCheck) {
            onSuccess();
            dispatch(createLoader(false));
            dispatch({
                type: "CREATE_RESPONSE",
                payload: { profile: "data" },
            });
        } else {
            try {
                let { data } = await leads.postLead(payload);

                if (data) {
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: data,
                    });
                    onSuccess();
                    dispatch(createLoader(false));
                    //  alert('ThankYou');
                    // setAddState(false);
                } else {
                    dispatch(createLoader(false));
                }
            } catch (e) {
                // alert(e.response.data.error);
                if (e.response.data.error == "Conflict - duplicate") {
                    onSuccess();
                    dispatch(createLoader(false));
                    console.log(">>>", e.response.data.error);
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: { profile: "data" },
                    });
                } else {
                    let error = e.response.data.error
                        .match(/'([^']+)'/)[0]
                        .slice(1, -1);
                    dispatch({
                        type: "ERROR_RESPONSE",
                        payload: { [error]: true },
                    });
                    dispatch(createLoader(false));
                }
            }
        }
    };

export const setErrorResponse = () => async (dispatch) => {
    dispatch({
        type: "ERROR_RESPONSE",
        payload: null,
    });
};
