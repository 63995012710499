import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import BottomSection from "../components/BottomSection";
import { Card } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getOaIdAndSiteId, saveUrl } from "../store/actions/saveDataAction";
const Home = (props) => {
    let dispatch = useDispatch();
    const location = useLocation();
    const currentUrl = location.pathname;
    const search = location.search;
    const publisher_id = new URLSearchParams(search).get("site");
    const site_subid = new URLSearchParams(search).get("oa_id");
    console.log("==========>", currentUrl);
    useEffect(() => {
        dispatch(saveUrl(currentUrl));
    }, [currentUrl]);
    useEffect(() => {
        let data = {
            publisher_id,
            site_subid,
        };
        dispatch(getOaIdAndSiteId(data));
    }, [site_subid, publisher_id]);
    return (
        <>
            <div className="px-sm-3 mx-sm-3 Home">
                <HeroSection />
                <BottomSection />
            </div>
        </>
    );
};

export default Home;
