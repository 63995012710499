// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import newStraatReducer from "./newStraatReducer";
import newLeadReducers from "./postLeadReducer";
import saveDataReducer from "./saveDataReducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    strait: newStraatReducer,
    response: newLeadReducers,
    saveData: saveDataReducer,
});

export default rootReducer;
