import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
const Main = (props) => {
    return (
        <div>
            <Header />
            {props.children}
            <Footer />
        </div> 
    );
};

export default Main;
