const initialState = {
    publisher_id: null,
    site_subid: null,
    url: "",
};
const saveDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SAVE_URL":
            return {
                ...state,
                url: action.payload,
            };
        case "GET_OA_ID_AND_SITE_ID":
            return {
                ...state,
                publisher_id: action.payload.publisher_id,
                site_subid: action.payload.site_subid,
            };

        default:
            return { ...state };
    }
};
export default saveDataReducer;
