export const getOaIdAndSiteId = (data) => (dispatch) => {
    console.log(data);
    dispatch({
        type: "GET_OA_ID_AND_SITE_ID",
        payload: data,
    });
};

export const saveUrl = (data) => (dispatch) => {
    console.log(data);
    dispatch({
        type: "SAVE_URL",
        payload: data,
    });
};
