import firebase from "../../config/firebase";

export const getActiveUser =
    (onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            await firebase
                .firestore()
                .collection("maxMagazineNationaalActiveCounts")
                .onSnapshot((querySnapshot) => {
                    // console.log(querySnapshot.docs[0].data(), "data<<");

                    dispatch({
                        type: "ACTIVE_USERS",
                        payload: querySnapshot.docs[0]?.data() || {
                            activeUsers: 55,
                            totalCounts: 25555,
                        },
                    });
                    onSuccess(
                        querySnapshot.docs[0]?.data() || {
                            activeUsers: 55,
                            totalCounts: 25555,
                        }
                    );
                    // console.log(filteredPackages, "filteredPackages");
                });
        } catch (error) {
            console.log(error);
        }
    };

export const setActiveUser =
    (onSuccess = () => {}) =>
    async (dispatch) => {
        // console.log(payload, 'payload');
        try {
            if (localStorage.getItem("uniqueId") == null) {
                let newDocRef = await firebase
                    .firestore()
                    .collection("maxMagazineNationaalActiveUsers")
                    .doc();
                const uniqueId = newDocRef.id;
                localStorage.setItem("uniqueId", uniqueId);
            }
            let uniqueUser = localStorage.getItem("uniqueId");
            firebase
                .firestore()
                .collection("maxMagazineNationaalActiveUsers")
                .doc(uniqueUser)
                .set({
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                });
        } catch (error) {
            console.log(error);
        }
    };
