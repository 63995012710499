import React, { useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import { Questions } from "../components/QuestionSection/Questions";
import { steps } from "../store/data";

const QuestionPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [steps]);

    return (
        <div className="h-100vh">
            <Card className="questionSection mx-md-4 mx-3 my-md-4 mt-3 mb-0">
                <Row>
                    <Col>
                        <Questions steps={steps} />{" "}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default QuestionPage;
