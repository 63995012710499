import { RepositoryFactory } from "../../repository/RepositoryFactory";
var newStraat = RepositoryFactory.get("repo");

export const getStreet =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        console.log(payload, "payload");
        try {
            let { data } = await newStraat.getStreet(payload);
            if (data) {
                console.log(data, "data");
                dispatch({
                    type: "NEW_STRAAT",
                    payload: data,
                });
                dispatch({
                    type: "STREET_ERROR_RESPONSE",
                    payload: null,
                });
                onSuccess();
            }
        } catch (e) {
            onSuccess();
            dispatch({
                type: "NEW_STRAAT",
                payload: "data",
            });

            let error = e.response.data.error_id;

            if (error == "NO_RESULTS_FOUND") {
                dispatch({
                    type: "STREET_ERROR_RESPONSE",
                    payload: { zip: true },
                });
            }

            console.log("======>", e.response?.data);
        }
    };
export const setErrorResponse = () => async (dispatch) => {
    dispatch({
        type: "STREET_ERROR_RESPONSE",
        payload: null,
    });
};
