import React, { useEffect, useState } from "react";
import { Button, Card, Col, Input, Label, Row } from "reactstrap";
import FormSection from "./FormSection";
import InputCard from "../inputCards";
import { useSelector } from "react-redux";

export const Questions = ({ steps }) => {
    const [showThankYou, setShowThankYou] = useState(false);

    const { create_response, loading } = useSelector((state) => state.response);

    const [currentStep, setCurrentStep] = useState(0);
    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep, showForm]);
    const handleNextStep = () => {
        if (currentStep >= steps.length - 1) {
            setShowForm(true);
            setShowThankYou(true);
        } else {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const renderStep = () => {
        const currentStepData = steps[currentStep];

        return (
            <div>
                <Row className="row justify-content-center" key={currentStep}>
                    <div className="questionLeftCol col-md-12 col-lg-7">
                        <h6 className="px-3">
                            {currentStepData.questionNumber}
                        </h6>
                        <p className="px-3">
                            <b>{currentStepData.questionText}</b>
                            <span className="pl-sm-5">
                                {currentStepData.placeholderText}
                            </span>
                        </p>
                        <Row className="formRow">
                            {currentStepData.options.map((data, index) => (
                                <Col
                                    md={currentStep === 0 ? "12" : "6"}
                                    key={index}
                                >
                                    <Card className="py-3 px-2 m-2 QuestionCards">
                                        <div className=" mx-4 form-check form-switch">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`flexSwitchCheckChecked +${index}`}
                                            />

                                            <Label
                                                className="px-2 form-check-label"
                                                for={`flexSwitchCheckChecked +${index}`}
                                            >
                                                {data.text}
                                            </Label>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                            <div className="mt-4 bottomBtn">
                                <Button
                                    color="warning"
                                    className=""
                                    onClick={handleNextStep}
                                >
                                    {currentStepData.buttonText}
                                    <i className="fa fa-solid fa-chevron-right mx-3"></i>
                                </Button>
                            </div>
                        </Row>
                    </div>
                    <div className="ImgSection col-md-12 col-lg-5 img-fluid d-md-block d-none">
                        <img
                            src={currentStepData.img}
                            alt="img"
                            className="img-fluid questionImg"
                        />
                    </div>
                </Row>
            </div>
        );
    };

    return (
        <div>
            {showForm && (
                <FormSection
                    showForm={showForm}
                    setShowForm={setShowForm}
                    setShowThankYou={setShowThankYou}
                />
            )}

            {!showForm && !showThankYou && renderStep()}
        </div>
    );
};
