import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBwiMA4HScwhYjDRMlcoGzmjKofnuWlXiY",
    authDomain: "republish-react.firebaseapp.com",
    projectId: "republish-react",
    storageBucket: "republish-react.appspot.com",
    messagingSenderId: "249306848505",
    appId: "1:249306848505:web:f1fc4666522206c9de5628",
    measurementId: "G-H87KB5VR5X",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
