import StreetRepository from "./StreetRepository";
import postFormRepostiory from "./postFormRepostiory";
const LEADS = "leads";

export default {
    getStreet(payload) {
        return StreetRepository.get(
            `?authKey=P6JTU52clKYjZca8&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}`
        );
    },

    postLead(payload) {
        return postFormRepostiory.post(`${LEADS}`, payload);
    },
};
