import step1Img from "../assets/img/step 1-imgs.png";
import step2Img from "../assets/img/step2-group.png";
import step3Img from "../assets/img/step3-imgs.png";
export const steps = [
    {
        step: 1,
        options: [
            { text: "Sport", status: false },
            { text: "Nieuws/actualiteiten", status: false },
            { text: "Quiz", status: false },
            { text: "Films/documentaires", status: false },
            { text: "Series", status: false },
        ],
        questionNumber: "Vraag 1 van 3",
        questionText: "Wat voor soort tv programma’s kijkt u het liefst?",
        buttonText: "Volgende vraag",
        placeholderText: "(meerdere antwoorden mogelijk)",
        columns: 1,
        img: step1Img,
    },
    {
        step: 2,
        options: [
            { text: "Wie is de mol?", status: false },
            { text: "Radar", status: false },
            { text: "Boer zoekt vrouw", status: false },
            { text: "Flikken Maastricht", status: false },
            { text: "B&B vol liefde", status: false },
            { text: "Voetbal Inside", status: false },
            { text: "Heel Holland bakt", status: false },
            { text: "Slimste Mens", status: false },
            { text: "Masked Singer", status: false },
            { text: "Journaal", status: false },
            { text: "Even tot hier", status: false },
            { text: "Eenvandaag", status: false },
        ],
        questionNumber: "Vraag 2 van 3",

        questionText:
            "Kies uw favoriete tv programma(’s) uit onderstaande lijst",
        buttonText: "Volgende vraag",
        placeholderText: "(meerdere antwoorden mogelijk)",
        columns: 2,
        img: step2Img,
    },
    {
        step: 3,
        options: [
            { text: "NPO 1", status: false },
            { text: "SBS", status: false },
            { text: "NPO 2 ", status: false },
            { text: "Veronica", status: false },
            { text: "NPO 3", status: false },
            { text: "Net 5", status: false },
            { text: "RTL 4", status: false },
            { text: "RTL 5", status: false },
            { text: "Lokale omroepen", status: false },
            { text: "RTL 7", status: false },
            { text: "Ziggo Sport / ESPN", status: false },
            { text: "RTL 8", status: false },
            { text: "Discovery /National Geographic", status: false },
        ],
        questionNumber: "Vraag 3 van 3",

        questionText: "Kies uw favoriete tv zender(s) uit onderstaande lijst",
        buttonText: "Afronden",
        placeholderText: "(meerdere antwoorden mogelijk)",
        columns: 3,
        img: step3Img,
    },
];
