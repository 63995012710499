const initialState = {
    newStreet: null,
    error_newStreet: null,
};
const newStraatReducers = (state = initialState, action) => {
    switch (action.type) {
        case "NEW_STRAAT":
            return {
                ...state,
                newStreet: action.payload,
            };
        case "STREET_ERROR_RESPONSE":
            return {
                ...state,
                error_newStreet: action.payload,
            };
        default:
            return state;
    }
};
export default newStraatReducers;
